import { Outlet } from "react-router-dom";
import { ReactLenis } from "@studio-freight/react-lenis";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { MainContainer, OutletContainer } from "./LayoutComponents";
import { useFooter } from "../context/footerContext/useFooter";
import { HeaderColorProvider } from "../context/headerColorContext/HeaderColorProvider";

const lenisOptions = {
    lerp: 0.1,
    duration: 1.5,
    smoothTouch: false,
    smooth: true,
};

const Layout = () => {

    const { showFooter } = useFooter();

    return (
        <HeaderColorProvider>
            <MainContainer>
                <Header/>
                <ReactLenis root options={lenisOptions}>
                    <OutletContainer>
                        <Outlet/>
                    </OutletContainer>
                    {showFooter && <Footer />}
                </ReactLenis>
            </MainContainer>
        </HeaderColorProvider>
    );
};

export default Layout;
