import styled from "styled-components";
import { Header3 } from "../../../ui/text/HeaderComponents";

export const CardWrapper = styled.button`
    display: flex;
    flex-direction: row;
    gap: 16rem;
    background: none;
    justify-content: space-between;
    align-items: center;

    padding: 64rem 0;
    border: none;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_500}`};
    
    ${({ theme }) => `
        @media ${theme.media.medium} {
          padding: 48px 0;
          gap: 16px;
        }
    `};
    
    &:hover {
        h3, svg {
            color: ${({ theme }) => theme.colors.primary_500};
        }
        cursor: pointer;
    }
    
`;

export const CardTextSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16rem;
    justify-content: start;
    color: ${({ theme }) => theme.colors.neutral_800};
    
    h3 {
        font-weight: 700;
        line-height: 100%;
    }

    ${({ theme }) => `
        @media ${theme.media.medium} {
          gap: 16px;
        }
  `};
`

export const MoreButton = styled.div`
    background: none;
    border: none;
    svg {
        width: 48rem;
        height: 48rem;
    }
 
    color: ${({ theme }) => theme.colors.neutral_800};

    ${({ theme }) => `
        @media ${theme.media.large} {
            svg {
                width: 48px;
                height: 48px;
            }
        }
  `};
`

export const HeaderXXL = styled(Header3)`
    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 32px;
        }
  `};
`