import { ReactNode, useMemo } from "react";
import { VacanciesContext } from "./VacanciesContext";
import { SupportedLanguage } from "../../../constants/languages";
import { Vacancy, VacancyInfo } from "../../../types/vacancyTypes";

export const getVacancyData = (
    vacancy: Vacancy | undefined,
    language: SupportedLanguage
): VacancyInfo | undefined => {
    if (!vacancy) return undefined;

    const localizedVacancy = vacancy[language];

    if (localizedVacancy && Object.keys(localizedVacancy).length > 0) {
        return localizedVacancy;
    }

    return vacancy.ru;
};

const VacanciesProvider = ({ children }: { children: ReactNode }) => {
    const vacancies = useMemo(() => {
        try {
            const context = require.context("../../../vacancies", true, /.json$/);
            return context.keys().map((key) => {
                const fileName = key.replace(/^.*[\\/]/, "").replace(".json", "");
                const vacancyData = context(key) as Vacancy;
                return {
                    ...vacancyData,
                    fileName,
                };
            });
        } catch (error) {
            console.error("Error parsing files: ", error);
            return [];
        }
    }, []);

    return (
        <VacanciesContext.Provider value={{ vacancies }}>
            {children}
        </VacanciesContext.Provider>
    );
};


export default VacanciesProvider;