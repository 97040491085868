import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useLenis } from "@studio-freight/react-lenis";
import RoutesMap from "../../constants/routes";
import { SupportedLanguage, supportedLanguages } from "../../constants/languages";
import { Button } from "../ui/button/Button";
import NavBar from "../menu/NavBar";
import MobileMenu from "../menu/MobileMenu";
import { ControlsContainer, HeaderContainer, LogoIconComponent } from "./HeaderComponents";
import { ReactComponent as LogoLight } from "../../resources/icon/logo.svg"
import { ReactComponent as LogoDark } from "../../resources/icon/logo-dark.svg"
import useScrollDirection from "../../utils/useScrollDirection";
import { useHeaderColor } from "../context/headerColorContext/useHeaderColor";
import { baseTheme } from "../../styles/theme";

const getNextLanguage = (currLng: string) => {
    const currInd = supportedLanguages.indexOf(currLng as SupportedLanguage) || 0;
    const newInd = currInd + 1;
    return (newInd >= supportedLanguages.length) ? supportedLanguages[0] : supportedLanguages[newInd];
}

const Header = () => {
    const [isBackgroundLight, setIsBackgroundLight] = useState(false);
    const lenisInstance = useLenis();

    const headerColor = useHeaderColor();
    const scrollDirection = useScrollDirection();

    const [nextLanguage, setNextLanguage] = useState(getNextLanguage(i18next.language));
    const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);

    const handleLanguageChange = () => {
        i18next.changeLanguage(nextLanguage, (err) => {
            if (err) return console.log("something went wrong loading locales", err);
        }).then(() => {
            const newNextLanguage = getNextLanguage(i18next.language);
            setNextLanguage(newNextLanguage);
        });
    };

    const toggleScroll = (isOpen?: boolean) => {
        if (isOpen) {
            lenisInstance?.stop();
        } else {
            lenisInstance?.start();
        }
    };

    const toggleOpenMobileMenu = () => {
        toggleScroll(!openMobileMenu)
        setOpenMobileMenu(!openMobileMenu);
    }

    const closeMobileMenu = () => {
        toggleScroll(false);
        setOpenMobileMenu(false);
    }

    useEffect(() => {
        setIsBackgroundLight(headerColor === baseTheme.colors.white);
    }, [headerColor])

    return (
        <HeaderContainer $color={headerColor} $scrollDirection={scrollDirection || ""}>
            <Link to={RoutesMap.main} onClick={closeMobileMenu}>
                <LogoIconComponent>
                    {isBackgroundLight ? <LogoDark /> : <LogoLight />}
                </LogoIconComponent>
            </Link>
            <NavBar color={headerColor}/>
            <ControlsContainer>
                <Button variant={isBackgroundLight ? "lightTertiary" : "darkTertiary"} size="S" onClick={handleLanguageChange} >
                    {nextLanguage?.toUpperCase()}
                </Button>
                <MobileMenu
                    open={openMobileMenu}
                    toggleOpen={toggleOpenMobileMenu}
                />
            </ControlsContainer>
        </HeaderContainer>
    )
};

export default Header;
