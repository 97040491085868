import { ParagraphLVacancies } from "./VacanciesDetailsPageComponents";

interface TextWithSpacesProps {
    text?: string;
}

const TextWithSpaces = ({ text }: TextWithSpacesProps) => {
    return (
        <div>
            {
                text?.split("\n").map((line, index) => (
                <ParagraphLVacancies key={index} style={{ marginBottom: "12px" }}>
                    {line}
                </ParagraphLVacancies>
            ))
            }
        </div>
    )

}

export default TextWithSpaces;
