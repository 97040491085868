import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SupportedLanguage } from "../../../../constants/languages";
import VacanciesCard from "../vacanciesShortCard/VacanciesCard";
import { useVacancies } from "../../../context/vacanciesContext/useVcancies";
import { getVacancyData } from "../../../context/vacanciesContext/VacanciesProvider";
import { MAX_VACANCIES_MAIN_PAGE } from "../../../../constants/vacancies";

interface ParsedVacanciesShortProps {
    showLimitedAmount?: boolean;
}

const ParsedVacanciesShort = ({ showLimitedAmount }: ParsedVacanciesShortProps) => {
    const { vacancies } = useVacancies();
    const { i18n } = useTranslation();

    const displayedVacancies = useMemo(() => {
        if (!vacancies || vacancies.length === 0) return [];
        if (showLimitedAmount) {
            return vacancies.slice(0, Math.min(MAX_VACANCIES_MAIN_PAGE, vacancies.length));
        }
        return vacancies;
    }, [vacancies, showLimitedAmount]);

    const selectedVacanciesData = useMemo(() => {
        return displayedVacancies.map((vacancy) =>
            getVacancyData(vacancy, i18n.language as SupportedLanguage)
        );
    }, [displayedVacancies, i18n.language]);

    return (
        <>
            {selectedVacanciesData.map((vacancyData, index) => (
                <VacanciesCard
                    key={index}
                    title={vacancyData?.title}
                    description={vacancyData?.shortDescription}
                    fileName={displayedVacancies[index].fileName}
                />
            ))}
        </>
    );
};

export default ParsedVacanciesShort;
