import { ReactNode, useEffect, useRef, useState } from "react";
import { HeaderColorContext } from "./HeaderColorContext";
import { baseTheme } from "../../../styles/theme";
import { useLocation } from "react-router-dom";

const getColor = (element: HTMLElement): string => {
    const computedStyle = window.getComputedStyle(element);
    const bgColor = computedStyle.backgroundColor;

    const match = bgColor.match(/\d+/g);
    if (!match) return baseTheme.colors.black;

    const [r, g, b] = match.map(Number);
    return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;
};

export const HeaderColorProvider = ({ children }: { children: ReactNode }) => {
    const location = useLocation();
    const [headerColor, setHeaderColor] = useState<string>(baseTheme.colors.black);
    const headerHeightRef = useRef(baseTheme.sizes.headerNumber.height);
    const rafIdRef = useRef<number>();
    const sectionsRef = useRef<NodeListOf<HTMLElement>>();

    useEffect(() => {
        const header = document.querySelector("header");
        if (header) {
            headerHeightRef.current = header.getBoundingClientRect().height;
        }

        sectionsRef.current = document.querySelectorAll<HTMLElement>("section");

        const updateHeaderColor = () => {
            if (!sectionsRef.current) return;

            for (const section of sectionsRef.current) {
                const rect = section.getBoundingClientRect();
                if (rect.top <= headerHeightRef.current && rect.bottom >= 0) {
                    const color = getColor(section);
                    setHeaderColor(color);
                    break;
                }
            }

            rafIdRef.current = requestAnimationFrame(updateHeaderColor);
        };

        const handleScroll = () => {
            if (!rafIdRef.current) {
                rafIdRef.current = requestAnimationFrame(updateHeaderColor);
            }
        };

        window.addEventListener("scroll", handleScroll, { passive: true });
        rafIdRef.current = requestAnimationFrame(updateHeaderColor);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            if (rafIdRef.current) {
                cancelAnimationFrame(rafIdRef.current);
            }
        };
    }, [location.pathname]);


    return (
        <HeaderColorContext.Provider value={headerColor}>
            {children}
        </HeaderColorContext.Provider>
    );
};
