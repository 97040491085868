import { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import RoutesMap, { MenuPages } from "../../constants/routes";
import { LinksList, MenuLink, MenuLinkContainer, NavBarContainer } from "./MenuComponents";

interface NavBarProps extends HTMLAttributes<HTMLElement> {
    color?: string;
}

const NavBar = ({color, ...props }: NavBarProps) => {
    const { t } = useTranslation("translation", { keyPrefix: "menu" });

    return (
        <NavBarContainer {...props}>
            <LinksList $color={color}>
                {MenuPages.map((pageName) => (
                    <MenuLinkContainer key={pageName}>
                        <MenuLink to={RoutesMap[pageName]} $color={color}>
                            {t(pageName)}
                        </MenuLink>
                    </MenuLinkContainer>
                ))}
            </LinksList>
        </NavBarContainer>
    );
};

export default NavBar;
