import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
    BodyDescription,
    BodyHeader,
    HeaderXXLVacancies,
    PageWrapper,
    ParagraphLVacancies,
    VacancyBody,
    VacancyHeadSection
} from "./VacanciesDetailsPageComponents";
import { useVacancies } from "../../../context/vacanciesContext/useVcancies";
import { SupportedLanguage } from "../../../../constants/languages";
import List from "../../../ui/list/List";
import NotFoundVacancy from "../notFoundVacancy/NotFoundVacancy";
import TextWithSpaces from "./TextWithSpaces";
import { getVacancyData } from "../../../context/vacanciesContext/VacanciesProvider";
import { BlockFields } from "../../../../types/vacancyTypes";

type VacancyBodyFields = "requirements" | "required" | "optional" | "offer";

const VacancyDetailsSection = () => {
    const { vacancy } = useParams();
    const { vacancies } = useVacancies();
    const { i18n } = useTranslation();
    const selectedVacancy = vacancies.find((item) => item.fileName === vacancy);

    const selectedVacancyData = useMemo(() => getVacancyData(vacancies.find((item) =>
        item.fileName === vacancy), i18n.language as SupportedLanguage),
    [vacancies, i18n.language])

    const getListItems = (field: VacancyBodyFields) => {
        return (selectedVacancyData?.vacancyBody[field]?.list ?? []).map(
            (element) => element
        );
    };

    if (!selectedVacancy) {
        return (
            <NotFoundVacancy />
        )
    }

    return (
        <PageWrapper>
            <VacancyHeadSection>
                <HeaderXXLVacancies>{selectedVacancyData?.title}</HeaderXXLVacancies>
                <TextWithSpaces text={selectedVacancyData?.description} />
            </VacancyHeadSection>

            <VacancyBody>
            {selectedVacancyData?.vacancyBody &&
                (Object.entries(selectedVacancyData?.vacancyBody) as [VacancyBodyFields, BlockFields][]).map(([key, value]) => (
                    <div key={key}>
                        <BodyHeader>{value.header}</BodyHeader>
                        <BodyDescription>
                            {value?.textUp && <ParagraphLVacancies>{value.textUp}</ParagraphLVacancies>}
                            <List listItems={getListItems(key)} />
                            {value.textDown && <ParagraphLVacancies>{value.textDown}</ParagraphLVacancies>}
                        </BodyDescription>
                    </div>
                ))}
            </VacancyBody>
        </PageWrapper>
    );
};


export default VacancyDetailsSection;