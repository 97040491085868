import styled from "styled-components";
import {TextProps, textStyles} from "../../../styles/common";


export const Header1 = styled.h1<TextProps>`
    font-size: 21rem;
    line-height: 140%;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 21px;
        }
    `};
    
    ${textStyles}
`;

export const Header2 = styled.h2<TextProps>`
    ${textStyles};
  
    font-size: 64rem;
    line-height: 100%;
    letter-spacing: -1.92rem;
    font-weight: 700;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 40px;
            letter-spacing: -1.2px;
        }
    `};
`;

export const Header3 = styled.h3<TextProps>`
    font-size: 48rem;
    line-height: 100%;

    ${textStyles}
`;

export const Header4 = styled.h4<TextProps>`
    font-size: 32rem;
    line-height: 120%;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 28px;
        }
    `};

    ${textStyles}
`;

export const Header5 = styled.h5<TextProps>`
    font-size: 24rem;
    line-height: 120%;

    ${textStyles}
`;