import styled from "styled-components";
import { ParagraphS } from "../../../ui/text/ParagraphComponents";
import { ReactComponent as PaperClip } from "../../../../resources/icon/paperclip.svg";


export const FeedbackBlockContainer = styled.section`
  padding: 128rem 40rem;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => `
    @media ${theme.media.medium} {
        padding: 96px 16px;
    }
  `};
`;

export const FormEntity = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60rem;

  & > :nth-child(n + 5) {
    grid-column: span 2;
  }

  margin-bottom: 48rem;

  ${({ theme }) => `
    @media ${theme.media.medium} {
     display: flex;
     flex-direction: column;
     gap: 48px;
     margin-bottom: 48px;
    }
   
  `};
`;

export const PaperClipIcon = styled(PaperClip)`
  height: 20rem;
  width: 20rem;
  align-self: center;

  ${({ theme }) => `
    @media ${theme.media.small} {
      height: 20px;
      width: 20px;
    }
  `};
`;

export const FileSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16rem;
  align-items: center;
  flex-wrap: wrap;

  ${({ theme }) => `
    @media ${theme.media.small} {
        flex-direction: column;
        align-items: start;
        gap: 16px;
    }
  `};
`;

export const FileInputLabel = styled.label`
  display: flex;
  flex-direction: row;
  gap: 6rem;
  cursor: pointer;
  text-transform: uppercase;
`;

export const TipText = styled(ParagraphS)`
  color: ${({ theme }) => theme.colors.neutral_400};
  align-self: center;
  ${({ theme }) => `
    @media ${theme.media.small} {
          align-self: start;
    }
  `};
`

export const SelectedFilesOptionsSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8rem;
  flex-wrap: wrap;

  ${({ theme }) => `
    @media ${theme.media.small} {
       display: flex;
       flex-direction: row;
       gap: 8px;
     
    }
  `};
`

export const SelectedFilesOptions = styled.div`
  display: flex;
  height: 32px;
  padding: 7rem 12rem;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 200px;
  font-size: 16rem;
  
  &:hover {
    border-color: ${({ theme }) => theme.colors.error};
  }

  ${({ theme }) => `
    @media ${theme.media.medium} {
        font-size: 16px;
        padding: 7px 12px;
    }
  `};
`;

export const StyledFileInput = styled.input`
  display: none;
`;

export const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  ${({ theme }) => `
    @media ${theme.media.small} {
        img {
          height: 16px;
          width: 16px;
        }
    }
  `};
`;