import styled from "styled-components";

export const VacanciesSectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.white};
`
export const VacanciesSectionContainer = styled.div`
    box-sizing: border-box;
    padding: 0 40rem 128rem;
    
    color: ${({ theme }) => theme.colors.neutral_800};
    
    ${({ theme }) => `
        @media ${theme.media.medium} {
            padding: 0 40px 96px;
        }
    `};

    button {
        width: 100%;
        &:hover {
            color: ${({ theme }) => theme.colors.neutral_800};
        }
    }
`

export const VacanciesWrapper = styled.div`
    display: flex;
    flex-direction: column;

    margin-bottom: 16rem;
    
    ${({ theme }) => `
    @media ${theme.media.medium} {
      margin-bottom: 8px;
    }
  `};
`;


export const MainPageVacanciesListWrapper = styled(VacanciesWrapper)`
    button:nth-last-child(1) {
        border-bottom: none;
    }
`


