export const baseTheme  = {
    colors: {
        primary_300: "#295BFF",
        primary_500: "#0030CC",
        primary_800: "#00218F",


        white: "#FFFFFF",
        neutral_200: "#F1F2F4",
        neutral_300: "#DCDDE0",
        neutral_400: "#ABACB1",
        neutral_500: "#7E8088",
        neutral_800: "#141414",
        darkGrey: "#1B1B1B",
        black: "#000000",

        error: "#FF2929",
    },
    media: {
        extraLarge: "(max-width: 1440px)",
        large: "(max-width: 1024px)",
        medium: "(max-width: 768px)",
        small: "(max-width: 480px)",
    },
    // in px
    sizes: {
        header: { height: "80rem", mobileHeight: "60px" },
        headerNumber: { height: 80, mobileHeight: 60 },
    },
}
