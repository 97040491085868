import { useTranslation } from "react-i18next";
import RoutesMap, { MenuPages } from "../../constants/routes";
import {
    Backdrop,
    LinksList, MenuCloseIconThemed,
    MenuLink,
    MenuLinkContainer,
    MenuOpenIconThemed,
    MobileMenuContainer,
    MobileMenuPopup,
    OpenMenuButton,
} from "./MenuComponents";
import { baseTheme } from "../../styles/theme";
import { useHeaderColor } from "../context/headerColorContext/useHeaderColor";


type MobileMenuProps = {
    open: boolean;
    toggleOpen: () => void;
}

export const MobileMenu = ({ open, toggleOpen }: MobileMenuProps) => {
    const { t } = useTranslation("translation", { keyPrefix: "menu" });
    const headerColor = useHeaderColor();

    const MenuOpenIcon = headerColor === baseTheme.colors.white ?  <MenuOpenIconThemed /> : <MenuOpenIconThemed $isDark />;
    const MenuCloseIcon = headerColor === baseTheme.colors.white ?  <MenuCloseIconThemed /> : <MenuCloseIconThemed $isDark/>;

    return (
        <MobileMenuContainer>
            <OpenMenuButton onClick={toggleOpen}>
                {open ? MenuCloseIcon : MenuOpenIcon}
            </OpenMenuButton>
            <MobileMenuPopup $show={open}>
                <LinksList $color={headerColor}>
                    {MenuPages.map((pageName) => (
                        <MenuLinkContainer key={pageName}>
                            <MenuLink to={RoutesMap[pageName]} onClick={toggleOpen} $color={headerColor}>
                                {t(pageName)}
                            </MenuLink>
                        </MenuLinkContainer>
                    ))}
                </LinksList>
                <Backdrop onClick={toggleOpen} />
            </MobileMenuPopup>
        </MobileMenuContainer>
    );
};

export default MobileMenu;
