import { useTranslation } from "react-i18next";
import { useLenis } from "@studio-freight/react-lenis";
import { ParagraphS } from "../ui/text/ParagraphComponents";
import { Button } from "../ui/button/Button";
import {
    ContactsBlock,
    FooterContainer,
    FooterNavBar,
    LogoIconComponent,
    MainInfoContainer,
} from "./FooterComponents";
import { ReactComponent as ArrowUpIcon } from "../../resources/icon/arrow-up.svg";
import { contactsEmail, displayedContactsPhone } from "../../constants/contacts";

const Footer = () => {
    const { t } = useTranslation("translation", { keyPrefix: "footer"});
    const lenisInstance = useLenis();

    const handleMoveUp = () => {
        if (lenisInstance) {
            lenisInstance.scrollTo("top");
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    return (
        <FooterContainer>
            <MainInfoContainer>
                <LogoIconComponent />
                <FooterNavBar/>
                <ContactsBlock>
                    <ParagraphS>{displayedContactsPhone}</ParagraphS>
                    <ParagraphS>{contactsEmail}</ParagraphS>
                </ContactsBlock>
            </MainInfoContainer>
            <Button variant="darkTertiary" size="S" onClick={handleMoveUp}>
                <ArrowUpIcon />
                {t("upButton")}
            </Button>
        </FooterContainer>
    );
};

export default Footer;
