import { NoVacanciesSectionWrapper } from "./NoVacanciesComponents";
import { ReactComponent as VacanciesImg } from "../../../../resources/icon/vacancies.svg";
import { Header5 } from "../../../ui/text/HeaderComponents";
import { useTranslation } from "react-i18next";

const NoVacancies = () => {
    const { t } = useTranslation("translation", { keyPrefix: "vacancies" });

    return (
        <NoVacanciesSectionWrapper>
            <Header5>{t("noVacancies")}</Header5>
            <VacanciesImg />
        </NoVacanciesSectionWrapper>
    );
}

export default NoVacancies;