import styled from "styled-components";
import {TextProps, textStyles} from "../../../../styles/common";

export const GreetingWrapper = styled.section`
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    padding: 128rem 40rem;
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    white-space: nowrap;
    max-width: 100%;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            padding: 128px 40px;
            align-items: center;
            justify-content: center;
        }
        
        @media ${theme.media.small} {
            white-space: wrap;
        }
    `};
`;

export const TextLine = styled.div`
    letter-spacing: -2.88px;

    &:nth-child(2) {
        margin-left: 20%;
    }

    &:nth-child(3) {
        margin-left: 40%;
    }

    ${({ theme }) => `
        @media ${theme.media.large} {
            &:nth-child(2) {
                margin-left: 10%;
            }

            &:nth-child(3) {
                margin-left: 20%;
            }
        }
        
        @media ${theme.media.medium} {
            &:nth-child(2) {
                margin-left: unset;
            }
            &:nth-child(3) {
                margin-left: unset;
            }
        }
    `};
`;

export const TextXXL = styled.h1<TextProps>`
    ${textStyles};
    color: ${({ theme }) => theme.colors.neutral_400};

    font-size: 96rem;
    line-height: 120%;
    font-weight: 700;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 48px;
            letter-spacing: -1.44px;
        }
        
        @media ${theme.media.small} {
            text-align: center;
        }
    `};
`