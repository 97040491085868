import ListItem from "./ListItem";
import { Wrapper } from "./ListComponents";

interface ListProps {
    listItems?: string[];
}

const List = ({ listItems }: ListProps) => {
    if (!listItems) {
        return null;
    }
    return (
        <Wrapper>
            { listItems?.map((item) => (<ListItem key={item} item={item}/>))}
        </Wrapper>
    )

};

export default List