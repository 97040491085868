import styled from "styled-components";
import { TextProps, textStyles } from "../../../styles/common";

export const SpanBase = styled.span<TextProps>`
    ${textStyles}
`;

// 48px от 1440
export const SpanXL = styled(SpanBase)`
    font-size: 48rem;
    line-height: 120%;
`;

export const SpanXXL = styled(SpanBase)`
    font-size: 96rem;
    line-height: 120%;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 48px;
        }
    `};
`