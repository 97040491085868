import styled, { css } from "styled-components";

export const HeaderContainer = styled.header<{$color?: string, $scrollDirection?: string}>`
    position: sticky;
    top: ${({$scrollDirection}) => $scrollDirection === "down" ? "-80rem" : "0"};
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    height: ${({ theme }) => theme.sizes.header.height};
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 20rem;
    box-sizing: border-box;
    background-color: ${({ $color }) => $color};

    z-index: 1000;

    ${({theme, $scrollDirection}) => css`
        @media ${theme.media.medium} {
            padding: 14px 16px;
            height: ${theme.sizes.header.mobileHeight};
            top: ${($scrollDirection === "down" ? "-80px" : "0")};
        }
    `}
`;

export const LogoIconComponent = styled.div`
    svg {
        height: 40rem;
        width: auto;
    }
    ${({theme}) => css`
        @media ${theme.media.medium} {
            svg {
                height: 28px;
            }
        }
    `}
`;

export const ControlsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16rem;

    ${({theme}) => css`
        @media ${theme.media.medium} {
            gap: 16px;
        }
    `}
`;
