import { CardTextSection, CardWrapper, HeaderXXL, MoreButton } from "./VacanciesCardComponents";
import { Header1 } from "../../../ui/text/HeaderComponents";
import {ReactComponent as Arrow } from "../../../../resources/icon/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import RoutesMap from "../../../../constants/routes";

interface VacanciesCardProps {
    title?: string;
    description?: string;
    fileName: string;
}

const VacanciesCard = ({
   title,
   description,
   fileName,
}: VacanciesCardProps) => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(`${RoutesMap.vacancies}/${fileName}`);
    }

    return (
        <CardWrapper onClick={handleNavigate}>
            <CardTextSection>
                <HeaderXXL>
                    {title}
                </HeaderXXL>
                <Header1>
                    {description}
                </Header1>
            </CardTextSection>
            <MoreButton>
                <Arrow />
            </MoreButton>
        </CardWrapper>
    )
}

export default VacanciesCard;
