import styled from "styled-components";

export const NoVacanciesSectionWrapper = styled.section`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: ${({ theme }) => theme.colors.neutral_800};
    
    gap: 24rem;
    padding: 0 40rem 0;

    svg {
        padding: 54rem 23rem 0 16rem;
    }
    
    h5 {
        max-width: 905rem;
        text-align: center;
    }
    
    ${({ theme }) => `
        @media ${theme.media.medium} {
            gap: 24px;
            padding: 0 40px 0;
            h5  {
                font-size: 21px;
                max-width: unset;
                align-self: stretch;
            }
            
             svg {
                padding: 54px 23px 0 16px;
            }
        }
        
        @media ${theme.media.small} {
             svg {
                padding: 37px 16px 0 11px;
                width: 316px;
                height: 306px;
             }
        }
    `};
    
`