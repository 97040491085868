import { AxiosError } from "axios";
import { TFunction } from "i18next";

export interface ServerErrorResponse {
    context: {
        body: string[];
    };
}

export type ErrorsType = {[fieldName: string]: string} & {common?: string};

const getFieldsError = (errors: string[]): ErrorsType => {
    const parsedErrors: ErrorsType = {};
    
    errors.forEach((error) => {
        
        const fieldNameRegex = /#\/(\w+):/;
        const matchFieldName = error.match(fieldNameRegex);
        const fieldName = matchFieldName?.at(1);
        
        if (fieldName) {
            const regex = new RegExp(`#/${fieldName}:\\s*`);
            parsedErrors[fieldName] =  error.replace(regex, "").replace(/\"/g, "").trim();
        }
    })
    
    return parsedErrors;
}


export const parseServerError = (error: AxiosError, t: TFunction<"translation", "main.feedbackForm">): ErrorsType => {
    
    const context = error?.response?.data as ServerErrorResponse;
    const common = t("serverErrors.unknown")
    
    if (!context?.context?.body) {
        return { common };
    }
    
    const { body: errors } = context.context;
    
    const filedErrors = getFieldsError(errors);
    
    if (Object.keys(filedErrors).length) {
        return filedErrors;
    }
    
    filedErrors.common = common;
    return filedErrors
}
