import {
    VacanciesSectionWrapper, VacanciesSectionContainer, VacanciesWrapper
} from "../../mainPage/vacanciesSection/VacanciesSectionComponents";
import VacanciesTicker from "../vacanciesTicker/VacanciesTicker";
import NoVacancies from "../noVacancies/NoVacancies";
import ParsedVacanciesShort from "./ParsedVacanciesShort";
import { useVacancies } from "../../../context/vacanciesContext/useVcancies";

const Vacancies = () => {
    const { vacancies } = useVacancies();

    return (
        <VacanciesSectionWrapper>
            <VacanciesTicker />
            {
                vacancies?.length
                ? <VacanciesSectionContainer>
                    <VacanciesWrapper>
                        <ParsedVacanciesShort />
                    </VacanciesWrapper>
                </VacanciesSectionContainer>
                : <NoVacancies />
            }
        </VacanciesSectionWrapper>
    );
}

export default Vacancies;
