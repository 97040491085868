import styled from "styled-components";

export const Wrapper = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    padding: 0;
`

export const Item = styled.span`
    display: flex;
    align-items: center;
    gap: 12px;
    
    color: ${({ theme }) => theme.colors.neutral_800};

    font-size: 21rem;
    line-height: 140%;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 18px;
            
            svg {
                width: 8px;
                height: 8px;
            }
        }
    `};
    
   
`;
