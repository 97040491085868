import { GreetingWrapper, TextLine, TextXXL } from "./GreetingSectionComponents";
import { Trans, useTranslation } from "react-i18next";
import { SpanXXL } from "../../../ui/text/SpanComponents";

const GreetingSection = () => {
    const { t } = useTranslation("translation", { keyPrefix: "vacancies"});
    return (
        <GreetingWrapper>
            <TextLine>
                <TextXXL>
                    <Trans
                        t={t}
                        i18nKey="greeting.newFriends"
                        components={{ span: <SpanXXL $weight={700} $color="white" /> }}
                    />
                </TextXXL>
            </TextLine>

            <TextLine>
                <TextXXL>
                    <Trans
                        t={t}
                        i18nKey="greeting.newProjects"
                        components={{ span: <SpanXXL $weight={700} $color="white" /> }}
                    />
                </TextXXL>
            </TextLine>

            <TextLine>
                <TextXXL>
                    <Trans
                        t={t}
                        i18nKey="greeting.newIdea"
                        components={{ span: <SpanXXL $weight={700} $color="white" /> }}
                    />
            </TextXXL>
            </TextLine>
        </GreetingWrapper>
    )
};

export default GreetingSection;
