import FeedbackFormSection from "../mainPage/feedbackFormSection/FeedbackFormSection";
import VacancyDetailsSection from "./details/VacancyDetailsSection";

const VacancyDetailsPage = () => {
    return (
        <>
            <VacancyDetailsSection />
            <FeedbackFormSection />
        </>
    )
}

export default VacancyDetailsPage;
