import CulturePageImageSection from "./imageSection/CulturePageImageSection";
import CulturePagePrinciplesSection from "./principlesSection/CulturePagePrinciplesSection";
import CulturePageOurLifeSection from "./ourLifeSection/CulturePageOurLifeSection";
import CulturePageDevelopingSection from "./developingSection/CulturePageDevelopingSection";
import CultureVacanciesSection from "../mainPage/vacanciesSection/VacanciesSection";
import CultureFeedbackFormSection from "../mainPage/feedbackFormSection/FeedbackFormSection";


const CulturePage = () => {


    return (
        <>
            <CulturePageImageSection />
            <CulturePagePrinciplesSection />
            <CulturePageOurLifeSection />
            <CulturePageDevelopingSection />
            <CultureVacanciesSection />
            <CultureFeedbackFormSection />
        </>
    );
}

export default CulturePage;
