import {
    MainPageVacanciesListWrapper, VacanciesSectionContainer, VacanciesSectionWrapper
} from "./VacanciesSectionComponents";
import { useTranslation } from "react-i18next";
import { Button } from "../../../ui/button/Button";
import VacanciesTicker from "../../vacanciesPage/vacanciesTicker/VacanciesTicker";
import { useNavigate } from "react-router-dom";
import RoutesMap from "../../../../constants/routes";
import NoVacancies from "../../vacanciesPage/noVacancies/NoVacancies";
import ParsedVacanciesShort from "../../vacanciesPage/vacancies/ParsedVacanciesShort";
import { useVacancies } from "../../../context/vacanciesContext/useVcancies";

const VacanciesSection = () => {
    const { t } = useTranslation("translation", { keyPrefix: "main.vacancies" });
    const navigate = useNavigate();
    const { vacancies } = useVacancies();

    const handleNavigate = () => {
        navigate(RoutesMap.vacancies);
    }

    return (
        <VacanciesSectionWrapper>
            <VacanciesTicker />
            {
                vacancies.length
                    ?
                    <VacanciesSectionContainer>
                        <MainPageVacanciesListWrapper>
                            <ParsedVacanciesShort showLimitedAmount={true}/>
                        </MainPageVacanciesListWrapper>
                        <Button variant="lightTertiary" size="L" onClick={handleNavigate}>
                            {t("allVacancies")}
                        </Button>
                    </VacanciesSectionContainer>
                    :
                    <NoVacancies />
            }

        </VacanciesSectionWrapper>
    );
};

export default VacanciesSection;
