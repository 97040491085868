import styled from "styled-components";

export const MainContainer = styled.div`
    // height: 100vh;
    width: 100%;
`;

export const OutletContainer = styled.main`
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
`;
