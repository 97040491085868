import { Card, CardsWrapper, Description, InfoWrapper } from "./CommonInfoComponents";
import { useTranslation } from "react-i18next";
import { Header5 } from "../../../ui/text/HeaderComponents";
import { ReactComponent as FullCompanyLogo } from "../../../../resources/icon/company-name-old.svg";
import { ReactComponent as ShortCompanyLogo } from "../../../../resources/icon/logo.svg";
import useScreenWidth from "../../../../utils/useScreenWidth";
import { screenSize } from "../../../../constants/screenSize";

const CommonInfo = () => {
    const { t } = useTranslation( "translation", { keyPrefix: "vacancies"});

    const cardsTextList = t("infoCards", { returnObjects: true });
    const screenWidth = useScreenWidth();

    return (
        <InfoWrapper>
            {screenWidth > screenSize.small ? <FullCompanyLogo /> : <ShortCompanyLogo />}
            <CardsWrapper>
                {cardsTextList.map(({title, description}, text) => (
                    <Card key={text}>
                        <Header5 $weight={600}>{title}</Header5>
                        <Description>{description}</Description>
                    </Card>
                ))}
            </CardsWrapper>
        </InfoWrapper>
    );
};

export default CommonInfo;
