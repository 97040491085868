import { SectionWrapper, VacanciesParagraphM } from "./NotFoundVacancyComponents";
import { Header2 } from "../../../ui/text/HeaderComponents";
import { Button } from "../../../ui/button/Button";
import { ReactComponent as NotFoundVacancies } from "../../../../resources/icon/notfoundVacancies.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import RoutesMap from "../../../../constants/routes";

const NotFoundVacancy = () => {
    const { t } = useTranslation("translation", { keyPrefix: "vacancies.notFound" });
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(RoutesMap.vacancies);
    }

    return (
        <SectionWrapper>
            <Header2>{t("title")}</Header2>
            <VacanciesParagraphM>{t("description")}</VacanciesParagraphM>
            <Button onClick={handleNavigate} variant="primary" size="L">{t("allVacancies")}</Button>
            <NotFoundVacancies/>
        </SectionWrapper>
    )
}

export default NotFoundVacancy;
