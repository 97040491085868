import { TickerContent, TickerItem, TickerWrapper } from "./VacanciesTickerComponents";
import { ReactComponent as Logo } from "../../../../resources/icon/logo_blue.svg";
import { useTranslation } from "react-i18next";
import { useVacancies } from "../../../context/vacanciesContext/useVcancies";
import { TICKER_REPEAT_COUNT } from "../../../../constants/vacancies";

const VacanciesTicker = () => {
    const { t } = useTranslation("translation", { keyPrefix: "main.vacancies" });
    const { vacancies } = useVacancies();

    const repeatedContent = Array.from({ length: TICKER_REPEAT_COUNT }, (_, i) => (
        <TickerItem key={i}>
            <Logo />
            {t("title")}
        </TickerItem>
    ));

    return (
        <TickerWrapper $noVacancies={!vacancies.length}>
            <TickerContent>
                {repeatedContent}
                {repeatedContent}
            </TickerContent>
        </TickerWrapper>
    )
};

export default VacanciesTicker;
