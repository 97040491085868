import styled from "styled-components";
import { ParagraphM } from "../../../ui/text/ParagraphComponents";

export const SectionWrapper = styled.section`
    padding: 64rem 40rem 0;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    color: ${({ theme }) => theme.colors.neutral_800};
    background-color: ${({ theme }) => theme.colors.white};

    svg {
        padding: 83rem 23rem 0 16rem;
    }
    
    button {
        margin-bottom: 24rem;
    }
    
    h2, p {
        text-align: center;
    }
    
    ${({ theme }) => `
        @media ${theme.media.medium} {
            padding: 64px 16px 0;
            
             svg {
                padding: 83px 23px 0 16px;
            }
            
             button {
                margin-bottom: 24px;
             }
        }
        
        @media ${theme.media.small} {
             svg {
                padding: 57px 16px 0 11px;
                width: 316px;
                height: 204px;
             }
        }
    `};
`;

export const VacanciesParagraphM = styled(ParagraphM)`
    margin: 16rem 0 32rem;
    
    ${({ theme }) => `
        @media ${theme.media.medium} {
            margin: 16px 0 32px;
            font-size: 18px;
        }
    `};
`;