import styled from "styled-components";
import { ParagraphL } from "../../../ui/text/ParagraphComponents";
import { Header2, Header4 } from "../../../ui/text/HeaderComponents";

export const PageWrapper = styled.section`
    padding: 64rem 40rem 128rem;
    color: ${({ theme }) => theme.colors.neutral_800};
    background-color: ${({ theme }) => theme.colors.white};

    ${({ theme }) => `
        @media ${theme.media.medium} {
            padding: 64px 16px 128px;
        }
    `};
`;

export const VacancyHeadSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 48rem;
    margin-bottom: 64rem;
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            gap: 24px;
            margin-bottom: 64px;
    `};
`;

export const HeaderXXLVacancies = styled(Header2)`
    font-weight: 700;
    
    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 40px;
        }
    `};
`

export const ParagraphLVacancies = styled(ParagraphL)`
    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 18px;
        }
    `};
`

export const VacancyBody = styled.section`
    display: flex;
    flex-direction: column;
    gap: 32rem;
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            gap: 32px;
    `};
`

export const BodyDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24rem;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            gap: 24px;
    `};
`

export const BodyHeader = styled(Header4)`
    margin-bottom: 16rem;
    
    font-weight: 600;
    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 21px;
            margin-bottom: 16px;

    `};
`
