import { Item } from "./ListComponents";
import circle from "../../../resources/icon/ellipse.svg";

export interface CustomListItemProps {
    item: string;
}

const ListItem = ({ item } : CustomListItemProps) => {
    return (
        <Item>
            <img src={circle} width={8} height={8} alt="circle"/>
            {item}
        </Item>
    );
}

export default ListItem;