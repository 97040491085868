import MainFeedbackFormSection from "./feedbackFormSection/FeedbackFormSection";
import MainPageIntroductionSection from "./introductionSection/MainPageIntroductionSection";
import MainPageDirectionsSection from "./directionsSection/MainPageDirectionsSection";
import MainPageInvitationSection from "./invitationSection/MainPageInvitationSection";
import MainPageTechnologiesSection from "./technologiesSection/MainPageTechnologiesSection";
import MainVacanciesSection from "./vacanciesSection/VacanciesSection";

const MainPage = () => {

    return (
        <>
            <MainPageIntroductionSection />
            <MainPageDirectionsSection />
            <MainPageInvitationSection />
            <MainPageTechnologiesSection />
            <MainVacanciesSection />
            <MainFeedbackFormSection />
        </>
    );
};

export default MainPage;
