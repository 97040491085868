import GreetingSection from "./greeting/GreetingSection";
import CommonInfo from "./commonInfo/CommonInfo";
import Vacancies from "./vacancies/Vacancies";
import FeedbackFormSection from "../mainPage/feedbackFormSection/FeedbackFormSection";

const VacanciesPage = () => {
    return (
        <>
            <GreetingSection />
            <CommonInfo />
            <Vacancies />
            <FeedbackFormSection />
        </>
    )
}

export default VacanciesPage;
