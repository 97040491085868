import styled from "styled-components";

export const InfoWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
    padding: 0 40rem 128rem;
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    
    gap: 128rem;
    
    svg {
        max-width: 100%;
        height: fit-content;
    }
    
    ${({ theme }) => `
        @media ${theme.media.medium} {
            padding: 0 16px 96px;
            gap: 64px;
        };
        
        @media ${theme.media.small} {
            svg {
                box-sizing: border-box;
                width: 100%;
                padding: 15px 18px 15px 15px;
            }
        }
    `};
`;

export const CardsWrapper = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20rem;

    ${({ theme }) => `
        @media ${theme.media.large} {
           grid-template-columns: 1fr 1fr;
        }
        
        @media ${theme.media.medium} {
           gap: 20px;
        }
        
        @media ${theme.media.small} {
           grid-template-columns: 1fr;
        }
    `};
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.darkGrey};

    box-sizing: border-box;
    gap: 16rem;
    padding: 32rem 32rem 48rem;
    
    border-radius: 16px;
    
    ${({ theme }) => `
        @media ${theme.media.medium} {
           padding: 24px 24px 32px;
           gap: 12px;
           
           h5 {
                font-size: 21px;
            }
            
            div {
                font-size: 18px;
            }
        } 
    `};
`;

export const Description = styled.div`
    color: ${({ theme }) => theme.colors.neutral_400};
    font-size: 18rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%

    ${({ theme }) => `
        @media ${theme.media.medium} {
           font-size: 18px !important;
        }
    `};
`;
