import styled, { keyframes } from "styled-components";

const tickerAnimation = keyframes`
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
`;

export const TickerWrapper = styled.div<{$noVacancies?: boolean}>`
    overflow: hidden;
    display: flex;
    white-space: nowrap;
    width: 100%;
    position: relative;
    margin: ${(props) => props.$noVacancies ? "128rem 0 32rem 0" : "128rem 0 64rem 0"};
  
    @media ${({ theme }) => theme.media.medium} {
      margin: ${(props) => props.$noVacancies ? "96px 0 24px 0;" : "96px 0 48px 0"};
    }
`;

export const TickerContent = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    min-width: 200%;
    animation: ${tickerAnimation} 15s linear infinite;
`;

export const TickerItem = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 120rem;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -3.6px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};

  svg {
    width: 250rem;
    height: 111rem;
  }
  
  ${({ theme }) => `
    @media ${theme.media.medium} {
      font-size: 120px;
    }
  `};
`;